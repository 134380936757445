<template>
    <div>
        <a-modal :visible="visible" width="680px" :title="`${souceUser.userName}负责的项目`" @cancel="handleCancel" >
            <a-spin :spinning="false" tip="正在加载">
                <div style="display: flex; ">
                    <a-badge :count="selectedRowKeys.length">
                        <a-button type="primary" :disabled="selectedRowKeys.length === 0" @click="handleHandOverButtonClick"> 移交项目 </a-button>
                    </a-badge>
                </div>
                <div class="table-ctn">
                    <a-table style="margin-top: 20px;" rowKey="id" :columns="tableColumn" size="small" :data-source="list" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }" :pagination="false" bordered :scroll="{ y: 500 }">

                    </a-table>
                </div>
            </a-spin>
            <template slot="footer">
                <div></div>
            </template>
        </a-modal>
        <a-modal :visible="handOverModalVisible" title="选择接收人（课题组内）" @cancel="handleHandOverModalCancel">
            <a-form-model :model="formData" ref="formRef" :colon="true" :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" :rules="rules">
                <a-form-model-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 15 }" label="项目接收人" prop="targetUserId">
                    <a-select v-model="formData.targetUserId" placeholder="请选择" show-search >
                        <a-select-option v-for="(option, optionIndex) in targetUserList" :key="option.userId">
                            {{ option.userName }} ({{ option.phone }})
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
            <template slot="footer">
                <div style="display: flex; justify-content: center;">
                    <a-button type="primary" :loading="isSubmitting" :disabled="isSubmitting" @click="handleHandOverModalConfirm"> 确定 </a-button>
                    <a-button :loading="isSubmitting" :disabled="isSubmitting" @click="handleHandOverModalCancel"> 取消 </a-button>
                </div>
            </template>
        </a-modal>
    </div>
</template>

<script>
import { computed, ref, watch } from 'vue-demi';
import { getResearchGroupUserChargeProject, handOverSelectedProject } from '@/api/researchGroup.js';
import { message } from 'ant-design-vue';
const tableColumn = [
    {
        dataIndex: "name",
        title: "项目名称",
    }, 
    {
        dataIndex: "code",
        title: "项目编号",
    },
]

export default {
    props: {
        visible: {
            default: false
        },
        souceUser: {
            default: () => ({})
        },
        userList: {
            default: () => ([])
        },
        researchGroupId: {
            default: ''
        }
    },
    setup(props, context) {

        // 是否有移交过项目
        const hasHandover = ref(false);

        watch(() => props.visible, async (newVal) => {
            if (newVal) {
                hasHandover.value = false;
                getProjectList();
                formData.value = {
                    targetUserId: undefined
                }
                isSubmitting.value = false
            }
            else {
                selectedRowKeys.value = []
            }
        })

        const getProjectList = async () =>  {
            isLoading.value = true;
                const res = await getResearchGroupUserChargeProject({
                    userId: props.souceUser.userId,
                    researchGroupId: props.researchGroupId
                });
                if(res.success) {
                    //this.list = 
                    list.value = res.data;
                }
                isLoading.value = true;
        }

        // 取消
        const handleCancel = () => {
            context.emit('update:visible', false);
            if(hasHandover.value) {
                context.emit('onHandOver')
            }
        }

        const isLoading = ref(false);   // 加载

        // 选择改变
        const onSelectChange = (newSelectedRowKeys) => {
            selectedRowKeys.value = newSelectedRowKeys;
        }

        // 选择的key值  
        const selectedRowKeys = ref([]);

        // 项目列表
        const list = ref([]);

        // 移交项目按钮点击
        const handleHandOverButtonClick = () => {
            handOverModalVisible.value = true;
        }


        /*************************         移交项目               **************************/
        // 移交项目对话框
        const handOverModalVisible = ref(false);

        // 移交项目表格
        const formData = ref({
            targetUserId: undefined
        });

        const formRef = ref();
        // 是否正在提交
        const isSubmitting = ref(false);

        const rules = ref({
            targetUserId: [
                {
                    required: true,
                    message: '请选择项目接收人'
                }
            ]
        })
        
        const targetUserList = computed(() => {
            return props.userList.filter(user => user.userId != props.souceUser.userId);
        })
        
        // 项目移交取消
        const handleHandOverModalCancel = () => {
            handOverModalVisible.value = false;
        }

        // 项目移交确认
        const handleHandOverModalConfirm = () => {
            formRef.value.validate(async (valid) => {
                if(valid) {
                    isSubmitting.value = true;
                    const res = await handOverSelectedProject({
                        ResearchGroupId: props.researchGroupId,
                        ProjectIds: selectedRowKeys.value,
                        SourceUserId: props.souceUser.userId,
                        TargetUserId: formData.value.targetUserId
                    });
                    if(res.success) {
                        message.success('成功移交');
                        hasHandover.value = true;
                        handOverModalVisible.value = false;
                        selectedRowKeys.value = [];
                        getProjectList();
                    }
                    isSubmitting.value = false;
                }
            })
        } 

        return {
            handleCancel,
            isLoading,
            tableColumn,
            onSelectChange,
            selectedRowKeys,
            list,
            handOverModalVisible,
            formData,
            formRef,
            isSubmitting,
            rules,
            targetUserList,
            handleHandOverButtonClick,
            handleHandOverModalCancel,
            handleHandOverModalConfirm
        }
    }
    /* data() {
        return {

        }
    },
    methods: {
        handleCancel() {
            this,
        }
    } */
}
</script>

<style lang="less" scoped>
.table-ctn {
    /deep/ .ant-table-thead {
        background: #F0F5FF;
    }

    /deep/ .ant-table-small > .ant-table-content > .ant-table-body {
        margin: 0px;
    }

    /deep/ .ant-table-row {
        td {
            padding: 7px;
        }
    }

    /deep/ .ant-table-row:hover {
        td:hover {
            .edit-cell {
                border: 1px solid #e4e4e4;
                background: #FFFFFF;

            }
        }
    }
}

</style>