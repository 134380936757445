<template>
    <a-modal width="800px" :visible="visible" title="添加成员" @cancel="handleCancel">
        <div>
            <div style="display: flex; justify-content: center; align-items: center;">
            <div style="font-size: 15px;">
                搜索成员 : 
            </div>
            <div style="width: 480px; margin-left: 16px;">
                <a-select style="width: 100%;" show-search :value="id"  @search="handleUserSearch"
                    @change="handleUserSelectChange" option-label-prop="label" placeholder="输入用户名称或手机号、邮箱" :filter-option="false"
                    :showArrow="false" >
                    
                    <a-select-option v-for="(user, userIndex) in searchList" :key="user.userId" :disabled="selectedIdList.indexOf(user.userId) !== -1 || memberIdList.indexOf(user.userId) !== -1">
                        <!-- {{  user.name  }} -->
                        <div style="display: flex; ">
                            <div>
                                <DefaultAvatar :url="user.avatar" :size="28" :name="user.name" ></DefaultAvatar>
                            </div>
                            <div style="margin-left: 10px;width: 0; flex: 1;">
                                <div style="word-wrap: break-word;">
                                    {{  user.name }} 
                                </div>
                                <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" :title="user.phone">
                                    手机号码：{{ user.phone }}
                                </div>
                                <div style="white-space: nowrap;overflow: hidden;text-overflow: ellipsis;" :title="user.email">
                                    邮箱： {{ user.email  }}
                                </div>
                            </div>
                            <div v-if="selectedIdList.indexOf(user.userId) !== -1 " style="color: #1890FF;margin-left: 10px;background: #fff;">
                                已添加
                            </div>
                            <div v-if="memberIdList.indexOf(user.userId) !== -1" style="color: #1890FF;margin-left: 10px;background: #fff;">
                                已在课题组中
                            </div>
                        </div>
                    </a-select-option>
                    <template slot="notFoundContent">
                        <a-spin v-if="fetching"  size="small" />
                        <a-empty v-else-if="!searchVal" description="输入名称或手机号、邮箱进行搜索"></a-empty>
                        <a-empty v-else-if="!fetching && searchVal && getFilterOption.length == 0" ></a-empty>
                    </template>
                    <!-- <a-empty v-if="!fetching && getFilterOption.length === 0 && searchVal"/> -->
                </a-select>
            </div>
        </div>
            <a-form-model :model="formData" ref="formRef" style="margin-top: 20px;">
                <a-form-model-item prop="list">
                    <div class="table-ctn">
                        <a-table size="small" :columns="tableColumns" :data-source="list" rowKey="userId"
                            :pagination="false">
                            <template slot="index" slot-scope="text, record, index">
                                {{ index + 1 }}

                            </template>
                            <template slot="avatar" slot-scope="text, record, index">
                                <div>
                                    <DefaultAvatar :url="record.avatar" :name="record.name"></DefaultAvatar>
                                </div>
                            </template>
                            <template slot="identityType" slot-scope="text, record, index">
                                <div>
                                    <a-select style="width: 100%;" :value="record.identityType"
                                        @change="val => handleIdentitySelect(val, record, index)">
                                        <a-select-option v-for="(option, optionIndex) in researchTeamIdentityList.filter(item => item.value != 1)"
                                            :key="option.value">
                                            {{ option.label }}
                                        </a-select-option>
                                    </a-select>
                                </div>
                            </template>
                            <template slot="hasManagementResearchGroup" slot-scope="text, record, index">
                                <div class="">
                                    <a-tooltip v-if="record.identityType == 1" title="PI默认包含管理权限">
                                        <a-switch :checked="record.hasManagementResearchGroup" :disabled="true"></a-switch>
                                    </a-tooltip>
                                    <a-switch v-else :checked="record.hasManagementResearchGroup"
                                        @change="checked => handleHasManageResearchGroupChange(checked, record, index)"></a-switch>
                                </div>
                            </template>

                            <template slot="hasCreateProject" slot-scope="text, record, index">
                                <div class="">
                                    <a-tooltip v-if="record.identityType == 1" title="PI默认包含创建权限">
                                        <a-switch :checked="record.hasCreateProject" :disabled="true"></a-switch>
                                    </a-tooltip>
                                    <a-switch v-else :checked="record.hasCreateProject"
                                        @change="checked => handleHasCreateProjectChange(checked, record, index)"></a-switch>
                                </div>
                            </template>
                            <template slot="operation" slot-scope="text, record, index">
                                <a-button type="link" @click="list.splice(index, 1)">删除</a-button>
                            </template>
                        </a-table>
                    </div>
                </a-form-model-item>
            </a-form-model>
        </div>
        <template slot="footer">
            <div style="display: flex; justify-content: center;">
                <a-button type="primary" :disabled="list.length === 0 || isSubmitting" :loading="isSubmitting" @click="handleConfirm"> 确定 </a-button>
                <a-button @click="handleCancel"> 取消 </a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { computed, ref, watch } from 'vue-demi';
import { researchGroupFindUser, addGroupUser } from '@/api/researchGroup.js'
import debounce from 'lodash/debounce';
import DefaultAvatar from '../../../../components/defaultAvatar.vue';
import { researchTeamIdentityList, researchTeamIdentityMap  } from '@/utils/enums.js';
import { message } from 'ant-design-vue';
const tableColumns = [
    {
        title: '序号',
        scopedSlots: { customRender: "index" }
    },
    {
        title: '头像',
        dataIndex: 'avatar',
        scopedSlots: { customRender: "avatar" }
    },
    {
        title: '成员名称',
        dataIndex: 'name',

        scopedSlots: { customRender: "name" }
    },
    {
        title: '身份',
        scopedSlots: { customRender: "identityType" }
    },
    {
        title: '课题组管理',
        scopedSlots: { customRender: "hasManagementResearchGroup" }
    },
    {
        title: '新建项目',
        scopedSlots: { customRender: "hasCreateProject" }
    },
    {
        title: '操作',
        scopedSlots: { customRender: "operation" }
    }
]

export default {
    props: {
        visible: {
            default: false
        },
        record: {
            default: () => {}
        },
        memberList: {
            default: () => []
        }
    },
    setup(props, context) {
        const list = ref([]); // 已选择的列表
        const searchList = ref([]); // 搜索的列表
        const id = ref(undefined); // 选择用户的id
        const fetching = ref(false); // 是否正在加载
        const searchVal = ref(""); // 搜索的关键词
        // 搜索用户请求
        const searchUser = debounce(async (val) => {
            searchVal.value = val;
            if (!val.toString()) {
                searchList.value = [];
                return;
            }
            fetching.value = true;
            const res = await researchGroupFindUser({
                userKey: val
            });
            if (res.success) {
                searchList.value = res.data;
            }
            fetching.value = false;
        }, 800);
        // 用户搜索
        const handleUserSearch = (val) => {
            searchVal.value = val.toString().trim();
            if (val.toString().trim()) {
                fetching.value = true;
            }
            else {
                fetching.value = false;
            }
            searchList.value = [];
            searchUser(val.toString().trim());
        };
        // 选择改变
        const handleUserSelectChange = (val, option) => {
            const selectedUser = searchList.value.filter(user => user.userId === val)[0]
            list.value.push({
                ...selectedUser,
                hasCreateProject: false,
                hasManagementResearchGroup: false,
                identityType: '3'
            })
            id.value = undefined;
            searchVal.value = undefined;

        };

        // 已选择用户的id列表
        const selectedIdList = computed(() => {
            return list.value.map(user => user.userId);
        })
        // 已在项目组内的用户Id
        const memberIdList = computed(() => {
            return props.memberList.map(user => user.userId);
        })
        // 过滤已选择的用户后的列表
        const getFilterOption = computed(() => {
            const ids = list.value.map(user => user.userId);
            return searchList.value.filter(user => {
                return ids.indexOf(user.userId) === -1;
            });
        });
        // 取消
        const handleCancel = () => {
            context.emit("update:visible", false);
        };

        // 是否正在添加成员
        const isSubmitting = ref(false);

        const formData = ref({
            list: []
        })


        // 确认添加
        const handleConfirm = async () => {
            isSubmitting.value = true;
            const res = await addGroupUser({
                id: props.record.id,
                list: list.value.map(user => ({
                    userId: user.userId,
                    identityType: user.identityType,
                    hasCreateProject: user.hasCreateProject,
                    hasManagementResearchGroup: user.hasManagementResearchGroup
                }))
            })
            if(res.success) {
                message.success('成功添加');
                context.emit('onAddMember');
                context.emit('update:visible', false);
            }
            isSubmitting.value = false;
           
        }


        watch(() => props.visible, (val) => {
            if (val) {
                searchList.value = [];
                searchVal.value = '';
                list.value = [];
            }
        })

        // 是否有课题组管理权限
        const  handleHasManageResearchGroupChange = (checked, record, index) => {
            list.value[index].hasManagementResearchGroup = checked;
        }

        // 是否有新建项目的权限
        const handleHasCreateProjectChange = (checked, record, index) => {
            list.value[index].hasCreateProject = checked
        }

        // 身份选择
        const handleIdentitySelect = (val, record, index) => {
            const user = list.value[index]
            user.identityType = val;
            if(val !== '3') {
                user.hasCreateProject = true;
                user.hasManagementResearchGroup = true;
            }
            else {
                user.hasCreateProject = false;
                user.hasManagementResearchGroup = false;
            }
        }

        return {
            list,
            searchList,
            id,
            handleUserSearch,
            handleUserSelectChange,
            tableColumns,
            getFilterOption,
            handleCancel,
            fetching,
            searchVal,
            selectedIdList,
            memberIdList,
            handleConfirm,
            isSubmitting,
            formData,
            researchTeamIdentityList,
            handleHasManageResearchGroupChange,
            handleHasCreateProjectChange,
            handleIdentitySelect
        };
    },
    components: { DefaultAvatar }
}
</script>

<style lang="less" scoped>
.table-ctn {
    margin-top: 20px;
    /deep/ .ant-table-thead {
        background: #F0F5FF;
    }

    /deep/ .ant-table-small>.ant-table-content>.ant-table-body {
        margin: 0px;
    }

    /deep/ .ant-table-row {
        td {
            padding: 7px;
        }
    }

    /deep/ .ant-table-row:hover {
        td:hover {
            .edit-cell {
                border: 1px solid #e4e4e4;
                background: #FFFFFF;

            }
        }
    }
}</style>