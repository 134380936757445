<!-- 移交项目对话框 -->
<template>
    <a-modal :visible="visible" with="480px" title="移交项目" @cancel="handleCancel">
        <a-form-model :model="formData" ref="formRef" :colon="true" :labelCol="{ span: 5 }" :wrapperCol="{ span: 19 }" :rules="rules">
            <a-form-model-item label="项目转移成员">
                {{ souceUser.userName   }} ( {{  souceUser.phone  }} )
            </a-form-model-item>
            <a-form-model-item label="负责项目数量">
                {{  souceUser.inChargeProjectQuantity  }}
            </a-form-model-item>
            <a-form-model-item :labelCol="{ span: 5 }" :wrapperCol="{ span: 15 }" label="项目接收人" prop="targetUserId">
                <a-select v-model="formData.targetUserId" placeholder="请选择" show-search >
                    <a-select-option v-for="(option, optionIndex) in targetUserList" :key="option.userId">
                    {{ option.userName }} ({{ option.phone }})
                    </a-select-option>
                </a-select>
            </a-form-model-item>
        </a-form-model>
        <template slot="footer">
            <div style="display: flex; justify-content: center;">
                <a-button type="primary" :disabled="isSubmitting" :loading="isSubmitting" @click="handleConform"> 确认 </a-button>
                <a-button :disabled="isSubmitting" :loading="isSubmitting" @click="handleCancel">  取消</a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { computed, ref, watch } from 'vue-demi';
import { handOverUserProject } from '@/api/researchGroup'
import { message } from 'ant-design-vue';
export default {
    props: {
        visible: {
            default: false
        },
        souceUser: {
            default: () => ({})
        },
        userList: {
            default: () => ([])
        },
        researchGroupId: {
            default: ''
        }
    },
    setup(props, context) {
        const handleCancel = () => {
            context.emit('update:visible', false);
        }
        // 目标用户列表
        const targetUserList = computed(() => {
            return props.userList.filter(user => user.userId != props.souceUser.userId);
        })
        // 
        const formData = ref({
            targetUserId: ''
        });

        const formRef = ref();

        const isSubmitting = ref(false);

        watch(() => props.visible, (val) => {
            if(val) {
                formRef.value && formRef.value.resetFields();
                formData.value = {
                    targetUserId: ''
                }
            }
        })

        const rules = ref({
            targetUserId: [
                {
                    required: true,
                    message: '请选择项目接收人'
                }
            ]
        })

        const handleConform = () => {
            formRef.value && formRef.value.validate(async(valid) => {
                if(valid) {
                    isSubmitting.value = true;
                    const res = await  handOverUserProject({
                        researchGroupId: props.researchGroupId,
                        sourceUserId: props.souceUser.userId,
                        targetUserId: formData.value.targetUserId
                    });
                    if(res.success) {
                        message.success('操作成功')
                        context.emit('update:visible', false);
                        context.emit('onHandOver')
                    }
                    isSubmitting.value = false
                }
            })
        }
        return {
            handleCancel,
            targetUserList,
            formRef,
            formData,
            isSubmitting,
            rules,
            handleConform
        }
    }
}
</script>