// 课题组管理
import  request from "../utils/request";

// 搜索用户
export const researchGroupFindUser = (params) => {
    return request({
        url: '/researchGroup/findUsers',
        method: 'get',
        params
    })
}

// 新增课题组
export const addResearchGroup = (data) => {
    return request({
        url: '/researchGroup/addResearchGroup',
        method: 'post',
        data
    })
}

// 查询课题组
export const getResearchGroup = (data) => {
    return request({
        method: 'post',
        url: '/researchGroup/pageList',
        data
    })
}

// 启用/停用项目组
export const setReaseachGroupStatu = (data) => {
    return request({
        method: 'post',
        url: '/researchGroup/editWork',
        data
    })
}

// 课题组成员列表
export const getResearchGroupUserList = (params) => {
    return request({
        method: 'get',
        url: '/researchGroup/researchGroupUsersList',
        params
    })
}

// 移除课题组成员
export const deleteResearchGroupUser = (params) => {
    return request({
        method: 'get',
        url: '/researchGroup/deleteResearchGroupUser',
        params
    })
}

// 增加课题组成员
export const addGroupUser = (data) => {
    return request({
        method: 'post',
        url: '/researchGroup/addGroupUser',
        data
    })
}

// 修改课题组名称
export const editGroupName = (data) => {
    return request({
        method: 'post',
        url: '/researchGroup/editGroupName',
        data
    })
}

// 修改课题组成员信息： 身份权限
export const editGroupUser = (data) => {
    return request({
        method: 'post',
        url: '/researchGroup/editGroupUser',
        data
    })
}

// 移交项目
export const handOverUserProject = (params) => {
    return request({
        method: 'get',
        url: '/researchGroup/handOverProject',
        params
    })
}

// 查询课题组下成员个人电子实验记录
export const getGroupNotebooks = (params) => {
    return request({
        method: 'get',
        url: '/researchGroup/getGroupNoteBooks',
        params
    })
}

// 查询课题组下成员个人项目负责情况
export const getResearchGroupMemberProjectInfoList = (params) => {
    return request({
        method: 'get',
        url: '/researchGroup/getProjectInfos',
        params
    })
}

// 查询课题组下某个成员某个项目实验的分布情况（图表数据）
export const getResearchGroupMemberSingleProjectInfo = (params) => {
    return request({
        method: 'get',
        url: '/researchGroup/getExperimentByMonth',
        params
    })
}

// 查询移交项目时用户所负责的所有项目
export const getResearchGroupUserChargeProject = (params) => {
    return request({
        method: 'get',
        url: '/researchGroup/getResearchGroupListByUser',
        params
    })
}


// 移交选中的项目给课题组成员
export const handOverSelectedProject = (data) => {
    return request({
        method: 'post',
        url: '/researchGroup/SelectProjectHandover',
        data
    })
}

