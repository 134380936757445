<!-- 编辑课题组成员权限和身份 -->
<template>
    <a-modal :visible="visible" title="身份权限" width="480px" @cancel="handelCancel" >
        <a-form-model :model="formData" ref="formRef" :colon="true" :labelCol="{ span: 5,}" :wrapperCol="{ span: 18 }">
            <a-form-model-item label="成员名称">
                {{ record.userName  }}
            </a-form-model-item>
            <a-form-model-item label="手机">
                {{  record.phone  }}
            </a-form-model-item>
            <a-form-model-item :labelCol="{ span: 5}" :wrapperCol="{ span: 12 }" label="身份" prop="identityType">
                <a-select  v-model="formData.identityType">
                    <a-select-option v-for="(option, index) in getIdentityList" :key="option.value">
                        {{  option.label }}
                    </a-select-option>
                </a-select>
            </a-form-model-item>
            <a-form-model-item label="新建项目">
                <a-switch v-model="formData.hasCreateProject"></a-switch>
            </a-form-model-item>
            <a-form-model-item label="管理课题组">
                <a-switch v-model="formData.hasManagementResearchGroup" @change="handleChange"></a-switch>
            </a-form-model-item>
        </a-form-model>
        <template slot="footer">
            <div  style="display: flex; justify-content: center;">
                <a-button type="primary" :disabled="!isChange || isSubmitting" @click="handleConfirm" :loading="isSubmitting"> 确认</a-button>
                <a-button :disabled="isSubmitting" :loading="isSubmitting" @click="handelCancel"> 取消</a-button>
            </div>
        </template>
    </a-modal>
</template>

<script>
import { computed, ref, watch } from 'vue-demi';
import { researchTeamIdentityList, researchTeamIdentityMap  } from '@/utils/enums.js';
import { editGroupUser } from '../../../../api/researchGroup';
import { message, Modal } from 'ant-design-vue';
import store from '../../../../store';
export default {
    props: {
        record: {
            default: () => ({})
        },
        visible: {
            default: false
        },
        researchGroupId: {
            default: ''
        }
    },
    setup(props, context) {
        const formData = ref({ 
            
        }); // 成员信息表单

        const formRef = ref()// 表单ref

        watch( () => props.visible, (val) => {
            if(val) {
                formData.value = {
                    identityType: props.record.identityType.toString(),
                    hasCreateProject: props.record.hasCreateProject,
                    hasManagementResearchGroup: props.record.hasManagementResearchGroup
                }
                formRef.value && formRef.value.resetFields();
            }
        })

        // 取消
        const handelCancel = () => {
            context.emit('update:visible', false);
        }

        // 获取下拉选项
        const getIdentityList = computed(() => {
            return researchTeamIdentityList.value.filter(item => item.value != '1');  // PI身份不能选择
        })

        const isSubmitting = ref(false);

        // 数据是否放生更改
        const isChange = computed(() => {
            return formData.value.identityType != props.record.identityType || formData.value.hasCreateProject != props.record.hasCreateProject || formData.value.hasManagementResearchGroup != props.record.hasManagementResearchGroup;
        })
        // 确定
        const handleConfirm = async () => {
            isSubmitting.value = true;
            const res = await editGroupUser({
                researchGroupId: props.researchGroupId,
                userId: props.record.userId,
                identityType: formData.value.identityType != props.record.identityType ? formData.value.identityType : undefined,
                hasCreateProject: formData.value.hasCreateProject != props.record.hasCreateProject ? formData.value.hasCreateProject : undefined,
                hasManagementResearchGroup: formData.value.hasManagementResearchGroup != props.record.hasManagementResearchGroup ? formData.value.hasManagementResearchGroup : undefined
            })
            if(res.success) {
                message.success('修改成员信息成功');
                context.emit('onEditUserInfo', {...formData.value} )
                context.emit('update:visible', false)
            }
            isSubmitting.value = false;
        }
        const handleChange = (val) => {
            if(!val && store.state.userInfo.userId === props.record.userId) {
                Modal.confirm({
                    content: '关闭自己管理权限后，无法再对用户进行管理，是否确认关闭？',
                    onOk: () => {
                        //formData.value.hasManagementResearchGroup = false;
                    },
                    okText: '确认关闭',
                    cancelText: '取消关闭',
                    onCancel: () => {
                        formData.value.hasManagementResearchGroup = true;
                    }
                })
            }
        }
        return {
            formRef,
            formData,
            researchTeamIdentityList,
            handelCancel,
            getIdentityList,
            isSubmitting,
            handleConfirm,
            isChange,
            handleChange
        }
    }
}
</script>